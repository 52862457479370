@use '../abstracts/' as *;

.Slider {
  &--label {
    color: white;
    position: relative;
    top: 0px;
  }

  &--value {
    background-color: white;
    width: fit-content;
    position: absolute;
    padding: 2px 0px;
    font-weight: 700;
    font-size: 20px;
    border-radius: 2px;
    text-align: center;
  }

  &--tick {
    position: relative;
    width: 10px;
    height: 10px;
    background: white;
    position: absolute;
    transform: rotate(225deg);
    top: 28px;
  }

  &--value-area {
    width: 100%;
    position: relative;
    height: 44px;
  }
  &--input::-webkit-slider-thumb {
    margin-top: -9.55px;
    width: 26px;
    height: 26px;
    background: #8f56e8;
    border: 3.1px solid #ffffff;
    border-radius: 22px;
    cursor: pointer;
    -webkit-appearance: none;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  &--input {
    width: 100%;
    margin: 9.55px 0;
    background-color: transparent;
    -webkit-appearance: none;
    border-radius: 6px;
  }
  &--input:focus {
    outline: none;
  }
  &--input::-webkit-slider-runnable-track {
    background: transparent;
    border: 0;
    width: 100%;
    height: 8px;
    cursor: pointer;
  }

  &--input::-moz-range-track {
    background: rgba(137, 113, 169, 0.78);
    border: 0;
    border-radius: 25px;
    width: 100%;
    height: 8px;
    cursor: pointer;
  }
  &--input::-moz-range-thumb {
    width: 26px;
    height: 26px;
    background: #8f56e8;
    border: 3px solid #ffffff;
    border-radius: 22px;
    cursor: pointer;
  }
  &--input::-ms-track {
    background: transparent;
    border-color: transparent;
    border-width: 14.55px 0;
    color: transparent;
    width: 100%;
    height: 8px;
    cursor: pointer;
  }
  &--input::-ms-fill-lower {
    background: #7c619f;
    border: 0;
    border-radius: 50px;
  }
  &--input::-ms-fill-upper {
    background: rgba(137, 113, 169, 0.78);
    border: 0;
    border-radius: 50px;
  }
  &--input::-ms-thumb {
    width: 26px;
    height: 26px;
    background: #8f56e8;
    border: 3px solid #ffffff;
    border-radius: 22px;
    cursor: pointer;
    margin-top: 0px;
  }
  &--input:focus::-ms-fill-lower {
    background: rgba(137, 113, 169, 0.78);
  }
  &--input:focus::-ms-fill-upper {
    background: #9681b3;
  }
}
