@use '../abstracts/' as *;

.PointBox {
  padding: 48px;

  &--center {
    margin: 0 auto;
    max-width: 1300px;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.19);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    display: grid;
    @include media('>mobile') {
      grid-template-columns: 1fr 1fr 1fr;
    }
    grid-template-columns: 1fr;

    grid-gap: 24px;
    padding: 48px;
  }
  &--item {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #434343;
    background-image: url('../../images/grey-bullet.svg');
    background-position: 0 50%;
    background-repeat: no-repeat;
    text-indent: 30px;
  }
  &--title {
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    color: #434343;
    margin-bottom: 24px;
  }
}
