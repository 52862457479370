@use '../abstracts/' as *;

.TopNav {
  &--pricing {
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    padding-right: 15px;
    margin-left: 24px;
    white-space: nowrap;
    color: #525252;
    text-decoration: none;
  }
  &--pricing:hover {
    text-decoration: none;
  }

  &--dropdown {
    padding: 12px;
    min-width: 350px;
  }
  &--list {
    margin: 0 12px 36px 12px;
    list-style: none;
    padding: 0;

    a {
      text-decoration: none;
    }
    a:hover {
      text-decoration: underline;
      color: $blue;
    }

    li {
      color: #434343;
      margin: 12px;
    }
    li:hover {
      color: $blue;
    }
  }

  &--close {
    background-image: url('../../images/close.svg');
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    width: 24px;
    height: 24px;
    margin: 12px;
  }
  &--title {
    margin: 12px;
    font-weight: 600;
    font-size: 12px;
    line-height: 21px;
    text-transform: uppercase;
    color: rgba(67, 67, 67, 0.4);
  }
  &--mobile {
    grid-area: left;
    background-image: url('../../images/mobile-nav.svg');
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;

    @include media('>mobile') {
      display: none;
    }
  }
  &--desktop {
    display: none;

    @include media('>mobile') {
      justify-self: center;
      align-self: center;
      grid-area: center;
      display: grid;
      grid-template-columns: auto auto auto auto auto;
      grid-gap: 24px;
      margin: 0;
      list-style: none;
      width: min-content;
    }
  }
}
