$background-gray: #ebeced;
$black: #373d48;
$blue: #2581e3;
$button-gray: #edeef0;
$charcoal: #232b39;
$error-background: #fff5f5;
$error-border: #e2bfc0;
$gray: #3f434b;
$green: #5ab26d;
$grey: #3f434b;
$input-gray: #393d4d;
$light-grey: #d5d5d5;
$mid-gray: #909090;
$modal-background: rgba(0, 0, 0, 0.6);
$off-white: #ebeced;
$red: #ec3f3f;
$selected-grey: #f8f9f9;
$soft-blue: #daedf9;
$success-background: #f5fff7;
$success-border: #bfe2c4;
$transparent: rgba(0, 0, 0, 0);
$white: #ffffff;
