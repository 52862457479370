@use '../abstracts/' as *;

.SignIn {
  background-color: white;
    
    &--button {
        background: #ebeced;
        border-radius: 4px;
        border: 1px solid #d0d3d5;
        cursor: pointer;
        font-size: 13px;
        font-weight: 400;
        grid-column: 3;
        line-height: 26px;
        padding: 0 16px;
        white-space: nowrap;
    }

    &--modal {
        padding: 24px;
    }
    &--invite {
        font-size: 12px;
        margin-top: 6px;
        text-align: center;
        span {
            color: $blue;
            cursor: pointer;
            font-size: 12px;
            font-weight: bold;
        }
    }
        &--forgot {
            color: $blue;
            cursor: pointer;
            font-size: 12px;
            font-weight: bold;
            margin-top: 3px;
            text-align: center;
    }
}
