@use '../abstracts/' as *;

.TextGrid {
  padding: 50px 0;
  &--center {
    margin: 0 auto;
    max-width: 1300px;
  }
  &--grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 60px;
    padding: 24px;
  }
  &--title {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 34px;
    color: #434343;
  }
  &--text {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #434343;
  }
}
