@use '../abstracts/' as *;

.SelectInput {
  width: 100%;
  margin-bottom: 12px;
  position: relative;
  cursor: pointer;

  &--select {
    width: 100%;
    border-radius: 4px;
    height: 40px;
    border: 1px solid $light-grey;
    padding: 6px 12px;
    font-size: 14px;
    font-weight: normal;
    background: $background-gray;
    appearance: none;
    background-image: url('../../images/chevron-down.svg');
    background-position: calc(100% - 20px) 18px;
    background-repeat: no-repeat;
    cursor: pointer;
    line-height: 20px;
    color: #373d48;
  }

  &--none {
    width: 100%;
    border-radius: 4px;
    height: 40px;
    border: 1px solid $light-grey;
    padding: 6px 12px;
    font-size: 13px;
    background: $background-gray;
    appearance: none;
    background-image: url('../../images/chevron-down.svg');
    background-position: calc(100% - 20px) 18px;
    background-repeat: no-repeat;
    cursor: pointer;
    line-height: 20px;
    color: #797979;
    font-weight: 300;
  }

  &--select:focus {
    border: 1px solid lighten($blue, 30%);
    background: $white;
    font-weight: 400;
    appearance: none;
    background-image: url('../../images/chevron-down.svg');
    background-position: calc(100% - 20px) 18px;
    background-repeat: no-repeat;
    cursor: pointer;
  }

  &--error {
    border: 1px solid $error-border;
    background: $error-background;
    border-radius: 4px;
    margin-bottom: 12px;
    padding: 4px 6px 4px 36px;
    width: fit-content;
    position: absolute;
    right: 0;
    top: -35px;
    background-image: url('../../images/alert-red.svg');
    background-position: 5px 5px;
    background-repeat: no-repeat;
    font-size: 11px;
    font-weight: bold;
  }

  &--description {
    font-size: 11px;
    font-weight: 200;
    color: inherit;
  }

  &--error::after {
    content: '';
    width: 10px;
    height: 10px;
    border-top: 1px solid $error-border;
    border-left: 1px solid $error-border;
    background: $error-background;
    position: absolute;
    transform: rotate(225deg);
    top: 22px;
    right: 12px;
  }
  &--optional {
    position: absolute;
    right: 0;
    font-size: 11px;
    font-weight: 400;
    font-style: italic;
    color: $mid-gray;
  }
  &--label {
    margin: 2px 0;
    display: block;
    font-weight: 200;
    color: inherit;
  }
}
