@use '../abstracts/' as *;

.SettingsItem {
  &--outer {
    display: grid;
    @include media('>mobile') {
      grid-template-columns: 1fr 3fr;
    }
    grid-template-columns: 1fr;
  }
  &--inner {
    display: grid;
    @include media('>mobile') {
      grid-template-columns: 3fr 1fr;
    }
    grid-template-columns: 1fr;
  }

  &--left {
    padding: 12px;
    &-title {
      font-weight: 700;
    }
    &-subtitle {
      color: rgba(63, 67, 75, 0.52);
    }
  }
  &--right {
    padding: 12px;
  }
}
