@use '../../abstracts/' as *;

.Users {
  &--center {
    margin: 12px auto;
    max-width: 1300px;
  }
  &--filter {
    padding: 12px;
  }
  &--none {
    text-align: center;
    padding: 24px;
  }

  &--title {
    margin: 0 12px;
    height: 40px;
    line-height: 40px;
    margin-right: 12px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
  }
  &--grid {
    margin: 0 12px;
    display: grid;
    grid-template-columns: 200px 1fr;
    grid-gap: 12px;
    margin-bottom: 24px;
  }
  &--nav {
    background-color: white;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0px 2px 2px rgb(0 0 0 / 6%);
    border-radius: 5px;
    padding: 12px;
    border-left: 3px solid #4091e7;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    font-weight: 700;
    cursor: pointer;
  }
  &--info {
    background-color: white;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0px 2px 2px rgb(0 0 0 / 6%);
    border-radius: 5px;
  }
  &--info-title {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 12px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }
  &--info-delete {
    background-image: url('../../../images/close.svg');
    background-repeat: no-repeat;
    opacity: 0.5;
    cursor: pointer;
  }
  &--info-title-text {
    padding: 12px;
    line-height: 24px;
    font-weight: 700;
  }
  &--info-title-btn {
    height: 24px;
    line-height: 15px;
    padding-right: 2px;
    padding-top: 2px;
    cursor: pointer;
    margin: 12px;
  }
  &--info-header {
    text-align: left;
    display: grid;
    grid-template-columns: 1fr 1fr 200px;
    grid-gap: 12px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    background: linear-gradient(
      180deg,
      rgba(217, 217, 217, 0) 25%,
      rgba(217, 217, 217, 0.45) 100%
    );

    div {
      margin: 12px;
      line-height: 12px;
      color: #858585;
    }
  }
  &--info-row:hover {
    background-color: #f8f9f9;
  }
  &--info-row {
    cursor: pointer;
    display: grid;
    grid-template-columns: 1fr 1fr 200px;
    grid-gap: 12px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    div {
      pointer-events: none;
      margin: 12px;
      line-height: 17px;
      font-size: 13px;
      color: #848288;
    }
  }
}
