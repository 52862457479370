@use '../abstracts/' as *;

.ChangePassword {
  &--center {
    margin: 12px auto;
    max-width: 1300px;
  }
  &--modal {
    padding: 24px;
  }
}
