@use '../abstracts/' as *;

.CheckBox {
  &--input:checked {
    background: url('../../images/tick.svg') no-repeat 4px 4px #2785fa;
    box-shadow: none;
  }
  &--input {
    width: 18px;
    height: 18px;
    background: white;
    box-shadow: inset 0 0 0 1px #e5e5e5;
    vertical-align: middle;
    display: inline-block;
    padding: 0;
    margin-top: -3px;
    -webkit-appearance: none;
    -moz-appearance: none;
    border-radius: 2px;
    cursor: pointer;
    margin-bottom: 12px;
  }
  &--label {
    margin-left: 12px;
    position: relative;
    top: -6px;
  }
}
