@use '../abstracts/' as *;

.ServerError {
  background-image: url('../../images/alert-red.svg');
  background-position: 12px 12px;
  background-repeat: no-repeat;
  background-color: $error-background;
  border-radius: 4px;
  border: 1px solid $error-border;
  font-size: 12px;
  font-style: normal;
  font-weight: bold;
  margin-bottom: 12px;
  padding: 12px 12px 10px 48px;
}
