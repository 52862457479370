@use '../abstracts/' as *;

.ThreePoints {
  &--title {
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 12px;
  }
  &--center {
    margin: 0 auto;
    max-width: 1300px;
    display: grid;
    @include media('>mobile') {
      grid-template-columns: 1fr 1fr 1fr;
    }
    grid-template-columns: 1fr;
    grid-gap: 48px;
    padding: 48px;
  }
}
