@use '../abstracts/' as *;

.FileItem {
    background-color: #EBECED;
    border-radius: 4px;
    border-top-right-radius: 0;
    border: 1px solid $light-grey;
    cursor: pointer;
    height: 120px;
    overflow: hidden;
    position: relative;
    width: 138px;

    &--overlay-corner {
        background-color: $light-grey;
        background-image: url('../../images/corner.svg');
        background-size: cover;
        border-bottom-left-radius: 3px;
        height: 12px;
        position: absolute;
        right: -1px;
        top: -1px;
        width: 12px;
        z-index: 2;
    }
    &--overlay-corner-selected {
        background-color: $light-grey;
        background-image: url('../../images/corner.svg');
        background-size: cover;
        border-bottom-left-radius: 3px;
        border-bottom: 1px solid $blue;
        border-left: 1px solid $blue;
        height: 12px;
        position: absolute;
        right: -1px;
        top: -1px;
        width: 12px;
        z-index: 2;
    }
    &--selected {
        border: 1px solid $blue;
        overflow: visible;
    }
    &--not-selected {
        border: 1px solid $light-grey;
        overflow: visible;
    }

    &--overlay {
        bottom: 0;
        display: grid;
        grid-template-rows: 1fr auto;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;

        &-top {
            position: relative;
            img {
                left: 6px;
                position: absolute;
                top: 6px;
            }
        }
        &-bottom {
            background-color: rgba($color: #ffffff, $alpha: 0.9);
            border-bottom-left-radius: 3px;
            border-bottom-right-radius: 3px;
            border-top: 1px solid $light-grey;
            height: 42px;
            padding: 6px;
            &-name {
                font-size: 11px;
                font-style: normal;
                font-weight: bold;
                line-height: 15px;
                text-shadow: 0px 0px 3px #FFFFFF;
            }
            &-size {
                font-size: 10px;
                font-style: normal;
                font-weight: normal;
                line-height: 12px;
                overflow: hidden;
                text-shadow: 0px 0px 3px #FFFFFF;
            }
            &-buttons {
                display: grid;
                grid-gap: 6px;
                grid-template-columns: 1fr 1fr;
                margin-top: 6px;
                overflow: hidden;
            }
            &-download {
                background-color: #EBECED;
                background-image: url('../../images/download.svg');
                background-position: center;
                background-repeat: no-repeat;
                border-radius: 3px;
                border: 1px solid $light-grey;
                height: 30px;
            }
            &-download:hover {
                border: 1px solid darken($light-grey, 15%);
            }
            &-delete {
                background-color: #EBECED;
                background-image: url('../../images/bin.svg');
                background-position: center;
                background-repeat: no-repeat;
                border-radius: 3px;
                border: 1px solid $light-grey;
                height: 30px;
            }
            &-delete:hover {
                border: 1px solid darken($light-grey, 15%);
            }

        }
    }

    &--ButtonTransition-enter &--overlay-top img {
        height: 42px;
    }
    &--ButtonTransition-enter-active &--overlay-top img{
        height: 34px;
        transition: all 220ms cubic-bezier(0.22, 0.44, 0, 1);
    }
    &--ButtonTransition-enter-done &--overlay-top img{
        height: 34px;
    }
    &--ButtonTransition-exit &--overlay-top img{
        height: 34px;
    }
    &--ButtonTransition-exit-active &--overlay-top img{
        height: 42px;
        transition: all 220ms cubic-bezier(0.22, 0.44, 0, 1);
    }
    &--ButtonTransition-exit-done &--overlay-top img{
        height: 42px;
    }


    &--ButtonTransition-enter &--overlay-bottom {
        height: 42px;
    }
    &--ButtonTransition-enter-active &--overlay-bottom {
        height: 64px;
        transition: all 220ms cubic-bezier(0.22, 0.44, 0, 1);
    }
    &--ButtonTransition-enter-done &--overlay-bottom {
        height: 64px;
    }
    &--ButtonTransition-exit &--overlay-bottom {
        height: 64px;
    }
    &--ButtonTransition-exit-active &--overlay-bottom {
        height: 42px;
        transition: all 220ms cubic-bezier(0.22, 0.44, 0, 1);
    }
    &--ButtonTransition-exit-done &--overlay-bottom {
        height: 42px;
    }


    &--ButtonTransition-enter &--overlay-bottom-size {
        height: 12px;
    }
    &--ButtonTransition-enter-active &--overlay-bottom-size {
        height: 0px;
        transition: all 220ms cubic-bezier(0.22, 0.44, 0, 1);
    }
    &--ButtonTransition-enter-done &--overlay-bottom-size {
        height: 0px;
    }
    &--ButtonTransition-exit &--overlay-bottom-size {
        height: 0px;
    }
    &--ButtonTransition-exit-active &--overlay-bottom-size {
        height: 12px;
        transition: all 220ms cubic-bezier(0.22, 0.44, 0, 1);
    }
    &--ButtonTransition-exit-done &--overlay-bottom-size {
        height: 12px;
    }

    &--ButtonTransition-enter &--overlay-bottom-buttons {
        opacity: 0;
        height: 6px;
    }
    &--ButtonTransition-enter-active &--overlay-bottom-buttons {
        opacity: 1;
        height: 32px;
        transition: all 220ms cubic-bezier(0.22, 0.44, 0, 1);
    }
    &--ButtonTransition-enter-done &--overlay-bottom-buttons {
        opacity: 1;
        height: 32px;
    }
    &--ButtonTransition-exit &--overlay-bottom-buttons {
        opacity: 1;
        height: 32px;
    }
    &--ButtonTransition-exit-active &--overlay-bottom-buttons {
        opacity: 0;
        height: 6px;
        transition: all 220ms cubic-bezier(0.22, 0.44, 0, 1);
    }
    &--ButtonTransition-exit-done &--overlay-bottom-buttons {
        opacity: 0;
        height: 6px;
    }
}




