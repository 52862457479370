@use '../abstracts/' as *;

.TextImageMicro {
  &--center {
    cursor: pointer;
    text-align: left;
    padding: 12px;
    margin: 0 auto;
    max-width: 1300px;
    display: grid;
    grid-template-areas: 'left right';
    grid-gap: 24px;
  }
  &--title {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #434343;
  }

  &--text {
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    color: rgba(67, 67, 67, 0.61);
  }
}
.TextImageMicro:hover .TextImageMicro--title {
  color: $blue;
}
.TextImageMicro:hover .TextImageMicro--text {
  color: $blue;
}

.dark {
  color: white;
}
