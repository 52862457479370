@use '../abstracts/' as *;

.SideMenu {
  background-color: $modal-background;
  bottom: 0;
  cursor: pointer;
  display: grid;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 3;

  &--menu {
    &-right-narrow {
      background-color: $white;
      bottom: 0;
      box-shadow: 0px 12px 25px 5px rgb(0 0 0 / 25%);
      position: fixed;
      right: 0;
      top: 0;
      width: 350px;
    }
    &-right-regular {
      background-color: $white;
      bottom: 0;
      box-shadow: 0px 12px 25px 5px rgb(0 0 0 / 25%);
      position: fixed;
      right: 0;
      top: 0;
      width: 50%;
    }
    &-right-super {
      background-color: $white;
      bottom: 0;
      box-shadow: 0px 12px 25px 5px rgb(0 0 0 / 25%);
      position: fixed;
      right: 0;
      top: 0;
      width: 80%;
    }
    &-left-narrow {
      background-color: $white;
      border-right: 1px solid $grey;
      bottom: 0;
      box-shadow: 0px 12px 25px 5px rgb(0 0 0 / 25%);
      left: 0;
      position: fixed;
      top: 0;
      width: 350px;
    }
    &-left-regular {
      background-color: $white;
      bottom: 0;
      box-shadow: 0px 12px 25px 5px rgb(0 0 0 / 25%);
      left: 0;
      position: fixed;
      top: 0;
      width: 50%;
    }
    &-left-super {
      background-color: $white;
      bottom: 0;
      box-shadow: 0px 12px 25px 5px rgb(0 0 0 / 25%);
      left: 0;
      position: fixed;
      top: 0;
      width: 80%;
    }
    &-center-narrow,
    &-center-super {
      align-self: center;
      background-color: $white;
      border-radius: 12px;
      border: 1px solid $grey;
      box-shadow: 0px 12px 25px 5px rgb(0 0 0 / 25%);
      height: auto;
      justify-self: center;
      position: relative;
      width: 350px;
    }
    &-center-regular {
      align-self: center;
      background-color: $white;
      border-radius: 12px;
      border: 1px solid $grey;
      box-shadow: 0px 12px 25px 5px rgb(0 0 0 / 25%);
      height: auto;
      justify-self: center;
      position: relative;
      width: 450px;
    }

    &-close {
      cursor: pointer;
      opacity: 0.3;
      padding: 24px;
      position: absolute;
      right: 0;
      top: 0;
    }
    &-close:hover {
      opacity: 1;
    }
  }
  &--transition-enter {
    background-color: $transparent;
  }
  &--transition-enter-active {
    background-color: $modal-background;
    transition: all 300ms ease-in;
  }
  &--transition-exit {
    background-color: $modal-background;
  }
  &--transition-exit-active {
    background-color: $transparent;
    transition: all 300ms ease-out;
  }

  //// CENTER NARROW

  &--transition-enter &--menu-center-narrow {
    border: 1px solid $transparent;
    opacity: 0;
    transform: translateY(-80px);
  }
  &--transition-enter-active &--menu-center-narrow {
    opacity: 1;
    transform: translateY(0px);
    transition: all 300ms ease-in-out;
  }
  &--transition-exit &--menu-center-narrow {
    opacity: 1;
    transform: translateY(0px);
  }
  &--transition-exit-active &--menu-center-narrow {
    border: 1px solid $transparent;
    opacity: 0;
    transform: translateY(-100px);
    transition: all 300ms ease-in-out;
  }

  //// CENTER REGULAR

  &--transition-enter &--menu-center-regular {
    border: 1px solid $transparent;
    opacity: 0;
    transform: translateY(-80px);
  }
  &--transition-enter-active &--menu-center-regular {
    opacity: 1;
    transform: translateY(0px);
    transition: all 300ms ease-in-out;
  }
  &--transition-exit &--menu-center-regular {
    opacity: 1;
    transform: translateY(0px);
  }
  &--transition-exit-active &--menu-center-regular {
    border: 1px solid $transparent;
    opacity: 0;
    transform: translateY(-100px);
    transition: all 300ms ease-in-out;
  }

  //// CENTER SUPER

  &--transition-enter &--menu-center-super {
    border: 1px solid $transparent;
    opacity: 0;
    transform: translateY(-80px);
  }
  &--transition-enter-active &--menu-center-super {
    opacity: 1;
    transform: translateY(0px);
    transition: all 300ms ease-in-out;
  }
  &--transition-exit &--menu-center-super {
    opacity: 1;
    transform: translateY(0px);
  }
  &--transition-exit-active &--menu-center-super {
    border: 1px solid $transparent;
    opacity: 0;
    transform: translateY(-100px);
    transition: all 300ms ease-in-out;
  }

  //// LEFT NARROW

  &--transition-enter &--menu-left-narrow {
    transform: translateX(-400px);
  }
  &--transition-enter-active &--menu-left-narrow {
    transform: translateX(0px);
    transition: all 300ms ease-in-out;
  }
  &--transition-exit &--menu-left-narrow {
    transform: translateX(0px);
  }
  &--transition-exit-active &--menu-left-narrow {
    transform: translateX(-400px);
    transition: all 300ms ease-in-out;
  }

  //// LEFT REGULAR

  &--transition-enter &--menu-left-regular {
    opacity: 0;
    transform: translateX(-50%);
  }
  &--transition-enter-active &--menu-left-regular {
    opacity: 1;
    transform: translateX(0px);
    transition: all 600ms ease-in-out;
  }
  &--transition-exit &--menu-left-regular {
    opacity: 1;
    transform: translateX(0px);
  }
  &--transition-exit-active &--menu-left-regular {
    opacity: 0;
    transform: translateX(-50%);
    transition: all 600ms ease-in-out;
  }

  //// LEFT SUPER

  &--transition-enter &--menu-left-super {
    opacity: 0;
    transform: translateX(-80%);
  }
  &--transition-enter-active &--menu-left-super {
    opacity: 1;
    transform: translateX(0px);
    transition: all 400ms ease-in-out;
  }
  &--transition-exit &--menu-left-super {
    opacity: 1;
    transform: translateX(0px);
  }
  &--transition-exit-active &--menu-left-super {
    opacity: 0;
    transform: translateX(-80%);
    transition: all 400ms ease-in-out;
  }

  //// RIGHT NARROW

  &--transition-enter &--menu-right-narrow {
    transform: translateX(400px);
  }
  &--transition-enter-active &--menu-right-narrow {
    transform: translateX(0px);
    transition: all 300ms ease-in-out;
  }
  &--transition-exit &--menu-right-narrow {
    transform: translateX(0px);
  }
  &--transition-exit-active &--menu-right-narrow {
    transform: translateX(400px);
    transition: all 300ms ease-in-out;
  }

  //// RIGHT REGULAR

  &--transition-enter &--menu-right-regular {
    opacity: 0;
    transform: translateX(50%);
  }
  &--transition-enter-active &--menu-right-regular {
    opacity: 1;
    transform: translateX(0px);
    transition: all 400ms ease-in-out;
  }
  &--transition-exit &--menu-right-regular {
    opacity: 1;
    transform: translateX(0px);
  }
  &--transition-exit-active &--menu-right-regular {
    opacity: 0;
    transform: translateX(50%);
    transition: all 400ms ease-in-out;
  }

  //// RIGHT SUPER

  &--transition-enter &--menu-right-super {
    opacity: 0;
    transform: translateX(80%);
  }
  &--transition-enter-active &--menu-right-super {
    opacity: 1;
    transform: translateX(0px);
    transition: all 400ms ease-in-out;
  }
  &--transition-exit &--menu-right-super {
    opacity: 1;
    transform: translateX(0px);
  }
  &--transition-exit-active &--menu-right-super {
    opacity: 0;
    transform: translateX(80%);
    transition: all 400ms ease-in-out;
  }
}
