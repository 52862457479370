@use '../abstracts/' as *;

.FileInput {
  display: grid;
  height: 100%;
  position: relative;
  width: 100%;
  &--input-show {
    width: 100%;
    height: 100%;
    bottom: 0;
    cursor: pointer;
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    text-indent: -1000px;
    top: 0;
    z-index: 10;
  }
  &--input-show-drag {
    backdrop-filter: blur(3px);
    background-color: rgba($color: #fff, $alpha: 0.8);
    background-image: url('../../images/drop-files.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 40% 40%;
    bottom: 0;
    cursor: pointer;
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    text-indent: -1000px;
    top: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
  }
}
