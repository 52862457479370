@use '../abstracts/' as *;

.Copy {
  position: relative;
  &--button {
    width: 100%;
    height: 20px;
    background-image: url('../../images/copy.svg');
    background-position: left;
    background-repeat: no-repeat;
    cursor: pointer;
    span {
      margin-left: 30px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: auto;
    }
  }
  &--text {
    color: #848288;
    cursor: pointer;
    background-image: url('../../images/copy-small.svg');
    background-position: left;
    background-repeat: no-repeat;
    text-indent: 15px;
    user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    font-style: italic;
    font-weight: 400;
    font-size: 11px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &--text:hover {
    color: $blue;
  }

  &--notification {
    background-position-x: 12px;
    background-position-y: 12px;
    background-color: #f2f3f3;
    height: fit-content;
    width: fit-content;
    border-radius: 5px;
    text-align: center;
    position: absolute;
    top: -5px;
    left: -10px;
    font-size: 9px;
    font-weight: bold;
    font-style: italic;
    padding: 6px 12px 12px 12px;

    &-title {
      font-weight: 400;
      font-size: 11px;
      color: #848288;
      text-align: left;
      text-indent: 15px;
      user-select: none;
      -ms-user-select: none;
      -moz-user-select: none;
      -khtml-user-select: none;
      -webkit-user-select: none;
    }
  }

  &--NotificationTransition-enter {
    transform: translateY(20px);
    opacity: 0;
  }
  &--NotificationTransition-enter-active {
    transform: translateY(0px);
    opacity: 1;
    transition: all 3000ms cubic-bezier(0, 0.89, 0, 1);
  }
  &--NotificationTransition-exit {
    transform: translateY(0px);
    opacity: 1;
  }
  &--NotificationTransition-exit-active {
    opacity: 0;
    transform: translateY(-20px);
    transition: all 1000ms cubic-bezier(1, 0, 1, 0.02);
  }
}
