@use '../abstracts/' as *;

.Pricing {
  &--overflow {
    @include media('>mobile') {
      height: 200px;
    }
    height: 900px;
  }
  &--title-price {
    font-weight: 700;
    font-size: 38px;
    line-height: 54px;
    color: #434343;
  }
  &--title-tablet {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.16);
    box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.12);
    border-radius: 12px;

    @include media('>mobile') {
      position: absolute;
      text-align: left;
    }
    position: relative;

    top: 50px;
    width: 100%;
    height: 340px;
    padding: 24px 48px;
    text-align: center;
    font-weight: 400;
    font-size: 40px;
    color: #434343;

    ul {
      margin: 12px auto;
      @include media('>mobile') {
        margin: 12px 0;
      }
      padding: 12px 0;
      list-style-type: none;
      max-width: fit-content;
      text-align: left;

      li {
        margin: 6px 0;
        padding: 0;
        text-indent: 20px;
        background-image: url('../../images/green-tick.svg');
        background-repeat: no-repeat;
        background-size: 12px 12px;
        background-position: 0 3px;
      }
    }
  }
  &--title-root {
    position: relative;
  }
  &--spacer {
    height: 200px;
  }
  &--title-grid {
    display: grid;
    @include media('>mobile') {
      grid-template-columns: 1fr 1fr 1fr;
    }
    grid-template-columns: 1fr;

    grid-gap: 48px;
  }
  &--description {
    margin: 0 auto;
    max-width: 1000px;
    padding: 48px;
    color: white;
    text-align: center;
  }
  &--center {
    padding: 36px 12px;
    margin: 0 auto;
    max-width: 1300px;
    label {
      color: white;
    }
  }
  &--grid {
    padding: 48px 0;
    margin: 0 auto;
    max-width: 1300px;
    grid-gap: 12px;

    display: grid;
    @include media('>mobile') {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    grid-template-columns: 1fr;
    padding: 0 12px;

    & div {
      color: white;
    }
    &-value {
      font-size: 45px;
      font-weight: 800;
    }
  }
}
