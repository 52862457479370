@use '../abstracts/' as *;

.UserMenu {
  position: relative;
  cursor: pointer;
  &--overlay {
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 99;
  }
  &--name {
    line-height: 24px;
    text-transform: capitalize;
  }
  &--avatar {
    background-image: url('../../images/no-avatar.jpg');
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 12px;
    border: 1px solid #a3a3a3;
    box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.2);
  }
  &--button {
    display: grid;
    grid-template-columns: 35px auto;
  }
  &--menu {
    background-color: $white;
    border-radius: 5px;
    border: 1px solid $light-grey;
    box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.13);
    position: absolute;
    left: -90px;
    top: 34px;
    z-index: 100;
    min-width: 150px;
  }
  &--menu::after {
    background: $white;
    border-left: 1px solid $light-grey;
    border-top: 1px solid $light-grey;
    content: '';
    height: 8px;
    position: absolute;
    left: 96px;
    top: -5px;
    transform: rotate(45deg);
    width: 8px;
  }
  &--transition-enter {
    opacity: 0;
    transform: translateY(-10px);
  }
  &--transition-enter-active {
    opacity: 1;
    transform: translateY(0px);
    transition: all 300ms cubic-bezier(1, 0, 1, 0.02);
  }
  &--transition-exit {
    opacity: 1;
    transform: translateY(0px);
  }
  &--transition-exit-active {
    opacity: 0;
    transform: translateY(-10px);
    transition: all 300ms cubic-bezier(1, 0, 1, 0.02);
  }
}
