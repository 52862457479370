@use '../abstracts/' as *;

.SplitCenter {
  &--center {
    margin: 0 auto;
    max-width: 1300px;
    display: grid;
    @include media('>mobile') {
      grid-template-columns: 1fr 1fr;
    }
    grid-template-columns: 1fr;
    grid-gap: 48px;
    padding: 48px;
  }
}
