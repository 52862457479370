@use '../abstracts/' as *;

.FileOrFolder {
    position: relative;
    &--folder-input {
        cursor: pointer;
        height: 44px;
        opacity: 0;
        position: absolute;
        text-indent: -1000px;
        width: 100%;
    }
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        width: 220px;
        li {
            border-bottom: 1px solid $light-grey;
            font-weight: 600;
            padding: 12px;
        }

        li:nth-child(2) {
            border-bottom: none;
        }
        li:nth-child(1) {
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }
    }
    &--folder-input:hover ~ ul li:nth-child(1) {
        background-color: #f8f9f9;
    }

}




