@use '../abstracts/' as *;

.Password {
  margin-bottom: 12px;
  position: relative;
  width: 100%;

  &--strong {
    display: grid;
    grid-gap: 4px;
    grid-template-columns: repeat(auto-fit, minmax(48%, 1fr));
    margin-top: 12px;
    &-on {
      background-image: url('../../images/bullet-grey.svg');
      background-position: left;
      background-repeat: no-repeat;
      color: #909090;
      font-size: 11px;
      font-weight: 200;
      padding-right: 6px;
      text-indent: 15px;
    }
    &-off {
      background-image: url('../../images/bullet-green.svg');
      background-position: left;
      background-repeat: no-repeat;
      color: #909090;
      font-size: 11px;
      font-weight: 200;
      padding-right: 6px;
      text-indent: 15px;
    }
  }

  &--toggle-show {
    background-image: url('../../images/show.svg');
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    height: 40px;
    position: absolute;
    right: 0px;
    top: 0px;
    width: 40px;
  }

  &--toggle-hide {
    background-image: url('../../images/hide.svg');
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    height: 40px;
    position: absolute;
    right: 0px;
    top: 0px;
    width: 40px;
  }
  &--input {
    background: $background-gray;
    border-radius: 4px;
    border: 1px solid $light-grey;
    font-size: 14px;
    font-weight: normal;
    height: 40px;
    padding: 12px;
    width: 100%;
  }
  &--input:focus {
    background: $white;
    border: 1px solid lighten($blue, 30%);
    font-weight: 400;
  }
  &--input::placeholder {
    font-size: 13px;
    font-weight: 200;
  }
  &--input[type='password']:not(:placeholder-shown) {
    caret-color: $light-grey;
    font-size: 25px;
    line-height: -10px;
  }
  &--description {
    color: $mid-gray;
    font-size: 11px;
    font-weight: 200;
  }
  &--error {
    background-image: url('../../images/alert-red.svg');
    background-position: 5px 5px;
    background-repeat: no-repeat;
    background: $error-background;
    border-radius: 4px;
    border: 1px solid $error-border;
    font-size: 11px;
    font-weight: bold;
    margin-bottom: 12px;
    padding: 4px 6px 4px 36px;
    position: absolute;
    right: 0;
    top: -35px;
    width: fit-content;
  }
  &--error::after {
    background: $error-background;
    border-left: 1px solid $error-border;
    border-top: 1px solid $error-border;
    content: '';
    height: 10px;
    position: absolute;
    right: 12px;
    top: 20px;
    transform: rotate(225deg);
    width: 10px;
  }
  &--optional {
    color: $mid-gray;
    font-size: 11px;
    font-style: italic;
    font-weight: 400;
    position: absolute;
    right: 0;
  }
  &--label {
    display: block;
    font-weight: 200;
    margin: 2px 0;
  }
}
