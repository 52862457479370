@use '../abstracts/' as *;

.TextInput {
  margin-bottom: 12px;
  position: relative;
  width: 100%;
  &--large {
    height: 60px !important;
  }

  &--input[type='number'] {
    -moz-appearance: textfield;
  }
  &--input {
    background: $background-gray;
    border-radius: 4px;
    border: 1px solid $light-grey;
    font-size: 14px;
    font-weight: normal;
    height: 40px;
    padding: 12px;
    width: 100%;
    &-large {
      font-size: 30px;
      height: 60px;
    }
    &-disabled {
      background: linear-gradient(
        0deg,
        #f3f3f3 0%,
        rgba(243, 243, 243, 0) 100%
      ) !important;
    }
  }
  &--input::-webkit-outer-spin-button,
  &--input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  &--input:focus {
    background: $white;
    border: 1px solid lighten($blue, 30%);
    font-weight: 400;
  }
  &--input::placeholder {
    font-size: 13px;
    font-weight: 200;
  }
  &--input[type='password']:not(:placeholder-shown) {
    caret-color: $light-grey;
    font-size: 25px;
    line-height: -10px;
  }
  &--description {
    color: inherit;

    font-size: 11px;
    font-weight: 200;
  }
  &--error {
    background-image: url('../../images/alert-red.svg');
    background-position: 5px 5px;
    background-repeat: no-repeat;
    background: $error-background;
    border-radius: 4px;
    border: 1px solid $error-border;
    bottom: 35px;
    font-size: 11px;
    font-weight: bold;
    height: auto;
    line-height: 27px;
    margin-bottom: 12px;
    padding-right: 6px;
    position: absolute;
    right: 0;
    text-indent: 29px;
    width: fit-content;
    z-index: 9;
  }
  &--error::after {
    background: $error-background;
    border-left: 1px solid $error-border;
    border-top: 1px solid $error-border;
    content: '';
    height: 10px;
    position: absolute;
    right: 12px;
    top: 22px;
    transform: rotate(225deg);
    width: 10px;
  }
  &--optional {
    color: inherit;
    font-size: 11px;
    font-style: italic;
    font-weight: 400;
    position: absolute;
    right: 0;
  }
  &--label {
    display: block;
    font-weight: 200;
    margin: 2px 0;
    color: inherit;
  }
}
