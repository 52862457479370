@use '../abstracts/' as *;

.Header {
  background-color: $white;
  box-shadow: 1px 2px 2px rgb(0 0 0 / 13%);
  &--pulse-grid {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 12px;
  }
  &--top-nav {
    grid-area: left;
    display: none;

    @include media('>mobile') {
      grid-area: center;
      display: block;
      margin: 0;
    }

    list-style: none;
    li {
      cursor: pointer;
      display: inline;
      padding-right: 15px;
      margin-left: 54px;
      font-weight: 500;
      font-size: 15px;
      line-height: 24px;
      color: #525252;
      background-image: url('../../images/chevron-down.svg');
      background-repeat: no-repeat;
      background-position: right;
    }
  }
  &--progress-bar {
    margin-top: 24px;
    width: 300px;
    height: 30px;
    background: #e9ebfa;
    box-shadow: inset 0px 4px 8px 1px #d3d9f0;
    border-radius: 15px;
    padding: 2px;
    overflow: hidden;
  }
  &--progress {
    border: 2px solid #1e6dc2;
    background-color: #2581e3;
    background-image: repeating-linear-gradient(
      -45deg,
      transparent,
      transparent 1rem,
      #1e6dc2 1rem,
      #1e6dc2 2rem
    );
    background-size: 200% 200%;
    animation: barberpole 5s linear infinite;
    height: 26px;
    border-radius: 15px;

    -webkit-transition: width 1s ease-in-out;
    -moz-transition: width 1s ease-in-out;
    -o-transition: width 1s ease-in-out;
    transition: width 1s ease-in-out;
  }

  @keyframes barberpole {
    100% {
      background-position: 100% 100%;
    }
  }

  &--upload {
    padding: 24px;
    &-btn-title {
      font-weight: 700;
      font-size: 25px;
      line-height: 34px;
      color: #3c3c3c;
      margin-bottom: 24px;
    }
    &-btn-desc {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #737373;
    }
  }
  &--uploading {
    padding: 24px;
  }

  &--upload-or {
    text-align: center;
    position: relative;
    border-bottom: 1px solid #d5d5d5;
    height: 1px;
    margin-bottom: 24px;
    margin-top: 24px;
    span {
      width: 50px;
      margin: 0 auto;
      position: absolute;
      top: -8px;
      left: calc(50% - 25px);
      background-color: white;
      font-style: italic;
      font-weight: 500;
      font-size: 14px;
      line-height: 15px;
      color: rgba(60, 60, 60, 0.5);
    }
  }

  &--upload-btn:hover {
    border: 1px solid #2581e3;
  }
  &--upload-btn {
    cursor: pointer;
    background: linear-gradient(
      180deg,
      rgba(217, 217, 217, 0) 25%,
      rgba(217, 217, 217, 0.45) 100%
    );
    border: 2px dashed rgba(0, 0, 0, 0.14);
    border-radius: 5px;
    padding: 24px;
  }

  &--newfolder {
    padding: 24px;
    max-height: 100vh;
    overflow: scroll;
  }
  &--logo {
    grid-area: center;
    justify-self: center;
    filter: grayscale(0);
    transition: all 0.5s ease-in-out;
    @include media('>mobile') {
      grid-area: left;
    }
  }
  &--logo:hover {
    transition: all 0.5s ease-in-out;

    filter: grayscale(100%);
  }

  &--center {
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-template-areas: 'left center right';
    grid-gap: 24px;
    margin: 0 auto;
    max-width: 1300px;
    padding: 12px;
    align-items: center;
    &-nav {
      display: grid;
      grid-template-columns: 1fr auto auto;
      grid-gap: 12px;
    }
    &-nav input {
      height: 34px;
      background: #eff0f1;
      border-radius: 5px;
      border: 0px solid transparent;
      text-indent: 40px;
      background-image: url('../../images/search.svg');
      background-repeat: no-repeat;
      background-position: 12px center;
    }
    &-nav button:nth-of-type(1) {
      height: 34px;
      background: #2581e3;
      border: 1px solid #4091e7;
      border-radius: 3px;
      color: white;
      font-style: normal;
      font-weight: 800;
      font-size: 13px;
      line-height: 15px;
      padding: 0 24px;
      cursor: pointer;
    }
    &-nav button:nth-of-type(2) {
      height: 34px;
      background: #edeef0;
      border: 1px solid #edeef0;
      border-radius: 3px;
      color: #434649;
      font-style: normal;
      font-weight: 800;
      font-size: 13px;
      line-height: 15px;
      padding: 0 24px;
      cursor: pointer;
    }
  }
  &--sign-in {
    background: #ebeced;
    border-radius: 4px;
    border: 1px solid #d0d3d5;
    cursor: pointer;
    font-size: 13px;
    font-weight: 400;
    grid-column: 3;
    line-height: 26px;
    padding: 0 16px;
    white-space: nowrap;
  }
  &--menu {
    list-style-type: none;
    margin: 0;
    padding: 0;
    li {
      border-bottom: 1px solid #ececec;
      line-height: 35px;
      text-indent: 12px;
      cursor: pointer;
    }
    li:last-child {
      border-bottom: none;
    }
    li:hover {
      background-color: lighten($background-gray, 5%);
    }
    li:first-child {
      border-top-right-radius: 5px;
      border-top-left-radius: 4px;
    }
    li:last-child {
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 4px;
    }
    li:first-child + {
      background-color: red;
    }
  }
}
