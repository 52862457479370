@use '../abstracts/' as *;

.DropDown {
  position: relative;
  width: min-content;
  &--overlay {
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 99;
  }
  &--button {
    border-radius: 5px;
    border: 1px solid $light-grey;
    cursor: pointer;
    font-size: 11px;
    font-weight: 700;
    line-height: 19px;
    padding: 0 24px;
    white-space: nowrap;
  }
  &--text {
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    padding-right: 15px;
    margin-left: 24px;
    white-space: nowrap;
    color: #525252;
    background-image: url('../../images/chevron-down.svg');
    background-repeat: no-repeat;
    background-position: right;
  }

  &--menu {
    background-color: $white;
    border-radius: 5px;
    border: 1px solid $light-grey;
    box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.13);
    position: absolute;
    right: 0;
    top: 30px;
    z-index: 100;
    min-width: 200px;
  }
  &--menu::after {
    background: $white;
    border-left: 1px solid $light-grey;
    border-top: 1px solid $light-grey;
    content: '';
    height: 8px;
    position: absolute;
    right: 35px;
    top: -5px;
    transform: rotate(45deg);
    width: 8px;
  }
  &--transition-enter {
    opacity: 0;
    transform: translateY(-10px);
  }
  &--transition-enter-active {
    opacity: 1;
    transform: translateY(0px);
    transition: all 300ms cubic-bezier(1, 0, 1, 0.02);
  }
  &--transition-exit {
    opacity: 1;
    transform: translateY(0px);
  }
  &--transition-exit-active {
    opacity: 0;
    transform: translateY(-10px);
    transition: all 300ms cubic-bezier(1, 0, 1, 0.02);
  }
}
