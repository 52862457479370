@use '../../abstracts/' as *;

.About {
  &--center {
    text-align: left;
    padding: 36px 12px;
    margin: 0 auto;
    max-width: 1300px;
    display: grid;
    grid-template-columns: 200px 1fr;
  }
  &--doc-title {
    font-size: 48px;
    border-bottom: 1px solid lighten($black, 60%);
    margin: 12px 0;
  }
  &--section-title {
    font-size: 20px;
    font-weight: 600;
    margin-top: 24px;
    margin-bottom: 12px;
  }
  ul {
    a {
      color: $blue !important;

      list-style: none;
      li {
        color: $blue !important;
        line-height: 30px;
      }
    }
  }
  p,
  ul,
  li {
    color: rgb(57, 61, 77);
    font-size: 16px;
    line-height: 22px;
  }
  p {
    margin-bottom: 24px;
  }
  &--section-subtitle {
    font-size: 14px;
    font-weight: 600;
    font-style: italic;
    margin: 12px 0;
  }
  b {
    font-size: 16px;
    font-weight: 600;
  }
  table {
    margin: 48px 0;
  }
  td {
    padding: 12px 0;
    border-bottom: 1px solid lighten($black, 60%);
  }
  th {
    padding: 12px 0;
    font-size: 17px;
    font-weight: 700;
    border-bottom: 1px solid lighten($black, 60%);
  }
}
