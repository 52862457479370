@use '../abstracts/' as *;

.Title {
  &--center {
    text-align: center;
    padding: 60px 12px;
    margin: 0 auto;
    max-width: 1300px;
  }
  &--title {
    font-weight: 700;
    font-size: 47px;
    line-height: 57px;
    color: #434343;
    margin-bottom: 12px;
  }
  &--sub-title {
    font-weight: 300;
    font-size: 21px;
    line-height: 25px;
    text-align: center;
    color: rgba(0, 0, 0, 0.6);
  }
}

.dark {
  color: white;
}
