@use '../abstracts/' as *;

.TextImage {
  &--center-left {
    text-align: left;
    margin: 0 auto;
    max-width: 1300px;
    display: grid;
    @include media('>mobile') {
      grid-template-columns: 2fr auto;
      grid-template-areas: 'left right';
    }
    grid-template-columns: 1fr;
    grid-template-areas: 'right' 'left';

    grid-gap: 48px;
  }

  &--center-right {
    text-align: left;
    margin: 0 auto;
    max-width: 1300px;
    display: grid;
    @include media('>mobile') {
      grid-template-columns: auto 2fr;
      grid-template-areas: 'left right';
    }
    grid-template-columns: 1fr;
    grid-template-areas: 'left' 'right';
    grid-gap: 24px;
  }

  &--title {
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    color: #434343;
    margin-bottom: 12px;
    text-align: center;

    @include media('>mobile') {
      text-align: left;
    }
  }
  &--text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #434343;
    text-align: center;

    @include media('>mobile') {
      text-align: left;
    }
  }
  &--image {
    margin: 0 auto;
  }

  &--button {
    margin-top: 24px;
    @include media('>mobile') {
      width: 300px;
    }
  }
}
.dark {
  color: white;
}
