@use '../abstracts/' as *;

.FolderView {
  background: linear-gradient(
    180deg,
    #ffffff 0%,
    #ffffff 51.56%,
    rgba(255, 255, 255, 0.52) 100%
  );
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  display: grid;
  grid-template-rows: auto 35px;
  cursor: pointer;
  overflow: hidden;
  &--desc {
    user-select: none;
    font-style: italic;
    font-weight: 400;
    font-size: 11px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &--top {
    padding: 12px;
    &-folder {
      background-image: url('../../images/folder.svg');
      background-repeat: no-repeat;
      height: 34px;
      margin-bottom: 12px;
    }
    &-directory {
      background-image: url('../../images/directory.svg');
      background-repeat: no-repeat;
      height: 34px;
      margin-bottom: 12px;
    }
    &-name {
      font-weight: 600;
      font-size: 15px;
    }
    &-date {
      font-weight: 400;
      font-size: 12px;
      color: rgba(60, 60, 60, 0.5);
    }
  }
  &--bottom {
    background: rgba(196, 196, 196, 0.12);
    height: 35px;
    line-height: 35px;
    padding: 0 12px;
    overflow: hidden;
  }
}
