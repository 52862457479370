@use '../abstracts/' as *;

.BenefitBox {
  padding: 48px;

  &--center {
    margin: 0 auto;
    max-width: 1300px;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.19);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    display: grid;
    @include media('>mobile') {
      grid-template-columns: 1fr 1fr;
    }
    grid-template-columns: 1fr;

    grid-gap: 24px;
    padding: 48px;
  }
  &--item {
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    color: #434343;
    background-image: url('../../images/green-tick.svg');
    background-repeat: no-repeat;
    text-indent: 40px;
  }
}
