@use '../../abstracts/' as *;

.Funding {
  &--center {
    margin: 12px auto;
    max-width: 1300px;
  }
  &--title {
    margin: 0 12px;
    height: 40px;
    line-height: 40px;
    margin-right: 12px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
  }
  &--grid {
    margin: 0 12px;
    display: grid;
    grid-template-columns: 200px 1fr;
    grid-gap: 12px;
  }
  &--card {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.18);
    border-radius: 4px;
    &-expiry {
      color: lighten($black, 30%);
    }
    &-number {
      font-size: 14px;
      font-weight: bold;
    }
    &-item {
      display: grid;
      grid-template-columns: 3fr 1fr;
      border-bottom: 1px solid #e1e1e1;
      grid-gap: 12px;
      padding: 12px;
    }
    &-card {
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 4px;
    }
    &-section {
      display: grid;
      grid-template-columns: 1fr 3fr;
      border-bottom: 1px solid #e1e1e1;
      grid-gap: 12px;
    }
    &-info {
      padding: 24px;
    }
    &-title {
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      color: #3f434b;
      margin-bottom: 6px;
    }
    &-description {
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      color: rgba(63, 67, 75, 0.52);
    }
  }
  &--modal {
    padding: 24px;
  }
}
