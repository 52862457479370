@use '../../abstracts/' as *;

.StorageNodes {
  &--center {
    margin: 12px auto;
    max-width: 1300px;
  }
  &--form {
    display: grid;
    grid-gap: 12px;
  }
  &--complete {
    text-align: center;
    img {
      margin: 0 auto;
    }
    div {
      color: white;
      text-align: center;
    }
  }
  &--title {
    color: white;
    text-align: center;
    font-weight: 700;
    font-size: 20px;
    margin-top: 12px;
  }
  &--error {
    color: #ff7474;
    text-align: left;
    font-weight: 700;
    font-size: 18px;
    margin-top: 12px;
  }
}
