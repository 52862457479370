@use '../abstracts/' as *;

* {
  font-family: 'Nunito', sans-serif;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-size: 13px;
  font-weight: 400;
  color: $black;
}
a {
  color: $blue;
  font-weight: 600;
  cursor: pointer;
}
a:hover {
  text-decoration: underline;
}
