@use '../abstracts/' as *;

.Button {
  border-radius: 4px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
  width: 100%;
  &--disabled {
    box-shadow: none;
    cursor: not-allowed !important;
    filter: grayscale(70%) opacity(30%);
  }
  &--disabled:hover {
    box-shadow: none !important;
  }
  &--blue {
    background: $blue;
    border: 1px solid darken($blue, 20%);
    color: $white;
  }
  &--green {
    background: $green;
    border: 1px solid darken($green, 20%);
    color: $white;
  }
  &--red {
    background: $red;
    border: 1px solid darken($red, 20%);
    color: $white;
  }
  &--grey {
    background: $off-white;
    border: 1px solid darken($off-white, 20%);
    border: transparent;
    box-shadow: none;
    color: $charcoal;
    font-weight: 500;
  }
  &--loading {
    background-image: url('../../images/loading.svg');
    background-position: center;
    background-repeat: no-repeat;
    cursor: progress;
  }
  &--small {
    height: 20px;
    line-height: 20px;
  }
  &--normal {
    height: 40px;
    line-height: 39px;
  }
}

.Button:hover {
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.3);
}
.Button:active {
  box-shadow: none;
}
