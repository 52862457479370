@use '../abstracts/' as *;

.Team {
  background: #fff;

  &--item {
    background: #fff;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      'image name'
      'image position';
  }
  &--name {
    grid-area: name;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    color: #434343;
    margin-top: 12px;
  }
  &--position {
    grid-area: position;
    font-weight: 400;
    font-size: 22px;
    line-height: 27px;
    color: rgba(0, 0, 0, 0.6);
  }

  &--image {
    grid-area: image;
    margin-right: 24px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 1px solid #cfcfcf;
  }

  &--center-text {
    padding: 36px 12px 6px 12px;
    background: #fff;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: rgba(0, 0, 0, 0.5);

    @include media('>mobile') {
      padding: 36px 12px;
      margin: 0 auto;
      max-width: 1300px;
    }
  }

  &--center {
    display: grid;
    grid-gap: 48px;
    padding: 36px 12px 6px 12px;
    background: #fff;

    @include media('>mobile') {
      grid-template-columns: 1fr 1fr 1fr;
      padding: 36px 12px;
      margin: 0 auto;
      max-width: 1300px;
    }
  }
}
