@use '../abstracts/' as *;

.Menu {
  display: grid;
  grid-gap: 24px;

  &--menu-container {
    min-width: 90px;
    height: fit-content;
    border-radius: 5px;
    box-shadow: 1px 2px 2px rgb(0 0 0 / 13%);
  }

  &--menu-items {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  &--menu-item {
    border-bottom: 1px solid $off-white;
    padding: 12px;
    background: $white url('../../images/chevron.svg') no-repeat
      calc(100% - 12px) 18px;
    background-size: 6px;
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr;
  }

  &--menu-item:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  &--menu-item:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom: 0;
  }

  &--menu-item:hover {
    cursor: pointer;
    color: $blue;
    background: $selected-grey url('../../images/chevron-blue.svg') no-repeat
      calc(100% - 12px) 18px;
    background-size: 6px;
  }

  &--menu-item-selected {
    padding: 12px;
    background-color: $selected-grey;
    background-image: none;
    cursor: default;
    position: relative;
    color: $black;
    border-left: 3px solid $blue;
  }
}
