@use '../abstracts/' as *;

.Register {
    background-image: url('../../images/register-bg.jpg');
    height: 100vh;
    width: 100%;
    background-size: cover;
    background-position:center;
    &--modal {
        padding: 24px;
    }
    &--agree {
        margin-top: 12px;
        text-align: center;
        font-size: 11px;
        span {
            font-size: 11px;
            color: $blue;
            font-weight: bold;
        }
    }
}




