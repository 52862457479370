@use '../abstracts/' as *;

.Home {
  &--center {
    margin: 12px auto;
    max-width: 1300px;
  }
  &--speed {
    max-width: 300px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 1px 2px 2px rgb(0 0 0 / 13%);
    padding: 0 12px 12px 12px;
  }
}
