@use '../abstracts/' as *;

.NotFound {
  background: #f7fbff;
  border-top: 1px solid rgba(0, 0, 0, 0.14);
  padding: 80px 0;
  @include media('>mobile') {
    background-image: url('../../images/jumbobg.png');
    background-repeat: no-repeat;
    background-position: right top;
  }
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  &--404 {
    font-weight: 500;
    line-height: 130px;
    font-size: 120px;
    color: #434343;
  }
  &--title {
    font-weight: 700;
    font-size: 47px;
    line-height: 57px;
    color: #434343;
  }
  &--sub-title {
    font-weight: 300;
    font-size: 21px;
    line-height: 25px;
    text-align: center;
    color: rgba(0, 0, 0, 0.6);
  }
  &--button {
    width: 140px;
  }
}

.dark {
  color: white;
}
