@use '../abstracts/' as *;

.Heading {
  padding-bottom: 28px;

  &--title {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
  }

  &--subtitle {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
}
