@use '../abstracts/' as *;

.Card {
  background-color: white;
  border-radius: 5px;
  border: 1px solid $light-grey;
  box-shadow: 1px 2px 2px rgb(0 0 0 / 13%);
  display: grid;
  grid-template-columns: 1fr;
  height: fit-content;

  &--header {
    border-bottom: 1px solid $light-grey;
    display: grid;
    grid-template-columns: 1fr auto;
    padding: 12px;

    &-title {
      font-size: 14px;
      font-weight: bold;
    }
  }
  &--footer {
    border-top: 1px solid $light-grey;
    display: grid;
    grid-template-columns: 1fr auto;
    padding: 12px;
  }
}
