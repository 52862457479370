@use '../abstracts/' as *;

.SideDataGrid > div:only-child {
  border-bottom: none;
}
.SideDataGrid > div:last-child {
  border-bottom: none;
}

.SideDataGrid {
  border-radius: 5px;
  display: block;
  border: 1px solid rgba(213, 213, 213, 0.6);
  background-color: white;
  margin-bottom: 30px;
  position: relative;

  &--title {
    background: #f8f9f9;
    border: 1px solid #d5d5d5;
  }
  &--bottomArrow {
    margin-bottom: 31px;
  }

  &--bottomArrow::after {
    content: url('../../images/arrow-grey.svg');
    display: inline-block;
    width: 100%;
    height: 0px;
    position: absolute;
    padding-left: 50%;
  }

  &--description {
    position: absolute;
    top: -20px;
  }
  &--item {
    border: 4px solid green;
  }
  &--top {
    border: 3px solid pink;
  }
  &--bot {
    border: 3px solid orange;
  }
}
