@use '../abstracts/' as *;

.FileView {
  background: linear-gradient(
    180deg,
    #ffffff 0%,
    #ffffff 51.56%,
    rgba(255, 255, 255, 0.52) 100%
  );
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  display: grid;
  grid-template-rows: auto 35px;
  cursor: pointer;
  overflow: hidden;
  &--top {
    padding: 12px;
    &-folder {
      background-image: url('../../images/file.svg');
      background-repeat: no-repeat;
      height: 40px;
      margin-bottom: 12px;
    }
    &-name {
      font-weight: 600;
      font-size: 18px;
    }
    &-date {
      font-weight: 400;
      font-size: 12px;
      color: rgba(60, 60, 60, 0.5);
    }
  }
  &--bottom {
    background: rgba(196, 196, 196, 0.12);
    height: 35px;
    line-height: 35px;
    padding: 0 12px;
    overflow: hidden;
  }
}
.FileViewRow {
  height: 65px;
  background: linear-gradient(
    180deg,
    #ffffff 0%,
    #ffffff 51.56%,
    rgba(255, 255, 255, 0.52) 100%
  );
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  display: grid;
  grid-template-columns: 80px 1fr auto auto 350px;
  cursor: pointer;
  overflow: hidden;

  &--info {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 6px;
    overflow: hidden;
    &-left {
      text-align: right;
      font-weight: normal;
      margin-right: 6px;
    }
    &-right {
      text-align: left;
      font-weight: bold;
      margin-right: 6px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  &--hide {
    width: 0px;
    height: 0px;
    opacity: 0;
  }
  &--show {
    max-width: calc(100% - 12px);
    height: auto;
    opacity: 1;
    margin: 12px;
    max-width: 250px;
    max-height: 250px;
  }

  &--grid {
    height: 100%;
    display: grid;
    grid-template-rows: auto 1fr auto auto;
    overflow: scroll;
  }

  &--divider {
    border: 1px solid #f1f1f1;
  }

  &--section {
    margin: 24px;
  }

  &--folder {
    background-image: url('../../images/file.svg');
    background-repeat: no-repeat;
    height: 40px;
    margin: 12px;
  }
  &--name {
    font-weight: 600;
    font-size: 15px;
    line-height: 40px;
    color: #3c3c3c;
    margin: 12px 0;
  }
  &--date {
    margin: 12px;
    line-height: 40px;
    font-weight: 400;
    font-size: 14px;
    color: rgba(60, 60, 60, 0.61);
  }
  &--size {
    margin: 12px;
    line-height: 40px;
    font-weight: 400;
    font-size: 14px;
    color: rgba(60, 60, 60, 0.61);
  }
  &--bottom {
    background: rgba(196, 196, 196, 0.12);
    height: 88px;
    line-height: 40px;
    padding: 12px;
    overflow: hidden;
  }
}
