@use '../abstracts/' as *;

.FileBrowser {
  width: 100%;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  padding: 0 12px;
  color: #3c3c3c;

  &--empty {
    margin-top: 100px;
    text-align: center;
    div {
      color: #8292af;
    }
  }
  &--breadcrumbs {
    height: 40px;
    line-height: 40px;
    display: grid;
    grid-template-columns: auto 1fr 180px;
    margin-bottom: 6px;
    &-root {
      height: 40px;
      line-height: 40px;
      margin-right: 12px;
      cursor: pointer;
      font-size: 16px;
      font-weight: 600;
    }
    &-root:hover {
      text-decoration: underline;
    }
    &-path {
      height: 40px;
      line-height: 40px;
    }

    &-item {
      display: inline-block;
      margin-right: 12px;
      cursor: pointer;
      font-size: 16px;
      font-weight: 600;
      background-image: url('../../images/chevron.svg');
      background-repeat: no-repeat;
      text-indent: 18px;
      margin-left: 6px;
      background-position: left center;
    }
    &-item:hover {
      text-decoration: underline;
    }
  }

  &--view-control {
    display: grid;
    grid-template-columns: 1fr auto;
    margin: 0 12px;
  }
  &--grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    padding: 12px;
    grid-gap: 12px;
  }
  &--grid-row {
    display: grid;
    grid-template-columns: 1fr;
    padding: 12px;
    padding-bottom: 48px;
    grid-gap: 12px;
  }

  &--files {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
    li {
      display: inline-block;
      margin: 12px 0 0 12px;
    }
  }
  &--info {
    border-left: 1px solid $light-grey;
    display: grid;
    grid-gap: 24px;
    grid-template-rows: auto auto 1fr;
    padding: 12px;

    &-title {
      font-size: 18px;
      font-weight: bold;
    }
    &-meta {
      display: grid;
      grid-template-columns: 106px 1fr;
      margin-bottom: 15px;
      div:nth-child(odd) {
        font-size: 12px;
        font-weight: normal;
        line-height: 16px;
      }
      div:nth-child(even) {
        font-size: 12px;
        font-weight: bold;
        line-height: 16px;
      }
    }
  }
  &--subTitle {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 12px;
  }
}
