@use '../abstracts/' as *;

.Jumbo {
  background: #f7fbff;
  border-top: 1px solid rgba(0, 0, 0, 0.14);
  border-bottom: 1px solid rgba(0, 0, 0, 0.14);
  @include media('>mobile') {
    background-image: url('../../images/jumbobg.png');
    background-repeat: no-repeat;
    background-position: right top;
  }

  &--center {
    display: grid;
    grid-gap: 12px;
    padding: 36px 12px 6px 12px;

    @include media('>mobile') {
      grid-template-columns: 2fr 1fr;
      padding: 36px 12px;
      margin: 12px auto;
      max-width: 1300px;
    }
  }
  &--title {
    text-transform: capitalize;

    text-align: center;
    font-weight: 800;
    @include media('>mobile') {
      text-align: left;
      font-size: 62px;
      line-height: 64px;
    }
    font-size: 32px;
    line-height: 34px;
    color: #434343;
  }
  &--sub-title {
    text-align: center;
    font-weight: 400;
    @include media('>mobile') {
      text-align: left;
      font-size: 27px;
      line-height: 27px;
    }
    font-size: 14px;
    line-height: 17px;
    color: #434343;
  }
  &--button {
    @include media('>mobile') {
      width: 200px;
    }
  }
}
