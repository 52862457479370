@use '../abstracts/' as *;

.Footer {
  border-top: 1px solid rgb(229, 229, 229);

  a {
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
    color: $blue;
  }

  &--center {
    text-align: left;
    padding: 36px 12px;
    margin: 0 auto;
    max-width: 1300px;
  }
  &--top {
    display: grid;
    grid-template-columns: 1fr 1fr;

    @include media('>mobile') {
      grid-template-columns: 1fr 1fr 1fr 2fr;
    }
    margin-bottom: 24px;
    ul {
      list-style: none;
      padding: 0;
      li {
        color: #434343;
      }
      li:hover {
        color: $blue;
      }
    }
  }
  &--bottom {
    &-subscribe {
      position: absolute;
      right: 8px;
      top: 8px;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: #9c9c9c;
      border: 1px solid #9c9c9c;
      background-image: url('../../images/white-arrow-right.svg');
      background-repeat: no-repeat;
      background-position: center center;
      cursor: pointer;
    }
    &-title {
      font-weight: 600;
      font-size: 12px;
      line-height: 21px;
      text-transform: uppercase;
      color: rgba(67, 67, 67, 0.4);
    }
    &-links {
      display: grid;
      grid-gap: 24px;
      grid-template-columns: auto auto auto 1fr;
      margin: 12px 0;
    }
    &-form {
      margin-top: 12px;
    }
  }
}
